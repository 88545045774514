import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "./form.css"
import {
    Box,
    Container,
    Flex,
    Heading,
    Kicker,
    Section,
    Subhead,
    Text,
  } from "../components/ui"
  import { StaticImage } from "gatsby-plugin-image"
  import ButtonBook from "../components/buttonbook"

const Contact = () => {
    // Use useStaticQuery to get the form data for form 1.
    // There is no need to import the fragment ...GravityFormFields as this is
    // imported globally by the gatsby-plugin-gravity-forms plugin.
    const data = useStaticQuery(graphql`
      query survey {
        wp {
            ...GravityFormSettings
          }
          wpGfForm(databaseId: { eq: 2 }) {
            ...GravityFormFields
          }
        }
    `)
  
    // Then pass the data into the form component.
    return (
      <>
        <GatsbySeo
                    title='Michael Law | Whanganui East Survey'
                    description='Living In Whanganui East, Whanganui.'
                  />
      <Layout>
      <Section>
      <Container>
        <Flex gap={4} variant="responsive">
         
          <Box width="half">
          <Heading as="h1">
              Whanganui East Survey
            </Heading>
          <GravityFormForm data={data} />
           
          </Box>
        </Flex>
      </Container>
    </Section>   
      
        </Layout>
      </>
    )
  }
  
  export default Contact